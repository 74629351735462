import React, { useContext } from 'react'

import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'
import { Container, Totals, TotalItem, CheckoutButton } from './styles'

const Cart = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const lineItems = checkout.lineItems.map(item => (
    <LineItem key={item.id.toString()} item={item} />
  ))

  return (
    <Container>
      {lineItems}

      <Totals>
        <TotalItem>
          <span>Subtotal</span> <span>${checkout.subtotalPrice} USD</span>
        </TotalItem>
        <TotalItem>
          <span>Taxes</span> <span>${checkout.totalTax} USD</span>
        </TotalItem>
        <TotalItem>
          <span>Order Total</span> <span>${checkout.totalPrice} USD</span>
        </TotalItem>
      </Totals>

      <CheckoutButton
        onClick={handleCheckout}
        disabled={checkout.lineItems.length === 0}
      >
        Check out
      </CheckoutButton>
    </Container>
  )
}

export default Cart
