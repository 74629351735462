import styled from '@emotion/styled'
import { Button } from '~/utils/styles'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 1.5rem 0;
  margin: 0;
  font-size: 1rem;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &:last-of-type {
    margin-bottom: 3rem;
  }
`

export const ImageWrapper = styled.div`
  margin-right: 1rem;
  min-width: 80px;
  text-align: center;
`
export const Details = styled.div`
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      margin: 0.3rem 0;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`
export const Price = styled.div`
  text-align: right;
  margin-left: auto;
`
export const RemoveButton = styled(Button)`
  position: absolute;
  bottom: 0;
  right: 0;
  border: 0;
  padding: 0.5rem;
  margin-right: -0.5rem;
  background: none;
  color: white;
  &:hover {
    border: 0;
    background: none;
  }
  &:focus {
    border: 0;
  }
`
