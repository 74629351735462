import styled from '@emotion/styled'
import { Button } from '~/utils/styles'

export const Container = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  max-width: 500px;
`

export const Totals = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 2rem;

  li {
    margin: 0.3rem 0;
  }
`

export const TotalItem = styled.li`
  display: flex;
  justify-content: space-between;
`

export const CheckoutButton = styled(Button)`
  margin-left: auto;
`
