import React, { useContext } from 'react'
import { Link } from 'gatsby'

import StoreContext from '~/context/StoreContext'
import { Wrapper, ImageWrapper, Details, RemoveButton, Price } from './styles'

const LineItem = props => {
  const { item } = props

  const {
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)

  const variantImage = item.variant.image ? (
    <img
      src={item.variant.image.src}
      alt={`${item.title} product shot`}
      height="60px"
    />
  ) : null

  const selectedOptions = item.variant.selectedOptions
    ? item.variant.selectedOptions.map(
        option =>
          option.value !== 'Default Title' && `${option.name}: ${option.value} `
      )
    : null

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id)
  }

  return (
    <Wrapper>
      <ImageWrapper>
        <Link to={`/product/${item.variant.product.handle}/`}>
          {variantImage}
        </Link>
      </ImageWrapper>
      <Details>
        <ul>
          <li>
            {item.title}
            {`  `}
            {item.variant.title !== 'Default Title' && item.variant.title}
          </li>
          <li>{selectedOptions}</li>
          <li>Quantity: {item.quantity}</li>
        </ul>
      </Details>

      <Price>
        ${item.variant.price} USD
        <RemoveButton onClick={handleRemove}>Remove</RemoveButton>
      </Price>
    </Wrapper>
  )
}

export default LineItem
