import React from 'react'
import Cart from '~/components/Cart'
import { Overlay } from '~/utils/styles'

const CartPage = () => (
  <Overlay>
    <Cart />
  </Overlay>
)

export default CartPage
